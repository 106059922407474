import { css } from '@emotion/react'
// import React from 'react';
// FINANCIERA COMPONENTS
import { CardElevation,TitleIcon,ButtonDegrade,ModalInfo, MaterialInput} from '@findep/mf-landings-core'

//MATERIAL UI COMPONENTS 
import {Snackbar,Backdrop,Grid, Select, FormControl, InputLabel, Box, Container } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';

//MODULES PROPS SYSTEM

//ICONS MATERIAL UI
import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AddLocationIcon from '@material-ui/icons/AddLocation';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CircularProgress from '@material-ui/core/CircularProgress';

import  DatosPersonales  from './TusDatos'

const bDrop= css`  
   z-index:9999;
   color: '#fff';
`  
const root =css`
  flex-grow: 1;
`
const buttonCss = css`
    display: flex;
    justify-content: flex-center;
`

 const cmpTxt1= css` 
  text-align: center;    
  height: 93px; 
 `

 const cssSelect= css` 
  width:100%;
 `



class CorregirDatos extends DatosPersonales {

    componentDidUpdate(prevProp, prevState){
        const {
          nombre,      
          apellidoPaterno, 
          apellidoMaterno,      
          cp,
          asentamiento,
          estado,
          calle,
          noExt,  
          _errors                  
        } = this.state
        if (          
          nombre !== '' &&
          apellidoPaterno !== '' &&
          apellidoMaterno !== '' &&
          cp !== '' &&
          _errors.cp.error === false &&
          asentamiento !== '' &&
          calle !== '' &&
          noExt !== ''
        ) {
          if (!prevState.btnSubmit) {
            this.setState({ btnSubmit: true })
          }
        }else if(prevState.btnSubmit){
          this.setState({btnSubmit: false})
        }
      }
      
    render() {
        return (
            <Container justify="center" >
                <Grid container justify="center">
                    <Grid item style={{ textAlign: "center" }}>
                        <TitleIcon icon={PersonIcon} title="Corrección de Datos" textColor />
                    </Grid>
                </Grid>

                <Box component="span" m={1} style={{ textAlign: "center" }}>
                    <Typography component='h1' css={cmpTxt1}>
                        <strong>A continuación vuelve a proporcionar los datos solicitados</strong><br />
                </Typography>
                </Box>

                <Grid container spacing={2} display="flex" alignItems="center">
                    <Grid item xs={12} sm={6}>

                        <CardElevation  >
                            <div css={root}>
                                <Grid container spacing={2}>
                                    <Grid item xs={1}>
                                        <Box display="flex" justify="center" alignItems="center" height="80%">
                                            <PersonIcon />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <MaterialInput
                                            type="text"
                                            id="tus-datos-nombre"
                                            label="Nombre(s)"
                                            value={this.state.nombre}
                                            pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                                            accept=" A-ZÑÁÉÍÓÚÜ"
                                            transform={(value) => value.toUpperCase().slice(0, 60)}
                                            error={this.state._errors.nombre.error}
                                            onError={(error) => this._handleError('nombre', error)}
                                            errorMessage={this.state._errors.nombre.errorType}
                                            onChange={(_event, component) => this.handleChange('nombre', component)}
                                            required
                                            helperText="Ingresa tu(s) nombre(s)"
                                        />
                                    </Grid>

                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={11}>
                                        <MaterialInput
                                            id="tus-datos-apellido-paterno"
                                            type="text"
                                            label="Apellido paterno"
                                            value={this.state.apellidoPaterno}
                                            pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                                            accept=" A-ZÑÁÉÍÓÚÜ"
                                            transform={(value) => value.toUpperCase().slice(0, 50)}
                                            onError={(error) => this._handleError('apellidoPaterno', error)}
                                            errorMessage={this.state._errors.apellidoPaterno.errorType}
                                            onChange={(_event, component) => this.handleChange('apellidoPaterno', component)}
                                            required={true}
                                            error={this.state._errors.apellidoPaterno.error}
                                            disabled={this.state._errors.nombre.error || this.state.nombre === ''}
                                            helperText="Campo obligatorio"
                                        />
                                    </Grid>

                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <MaterialInput
                                            id="tus-datos-apellido-materno"
                                            type="text"
                                            label="Apellido materno"
                                            value={this.state.apellidoMaterno}
                                            pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                                            accept=" A-ZÑÁÉÍÓÚÜ"
                                            transform={(value) => value.toUpperCase().slice(0, 50)}
                                            onChange={(_event, component) => this.handleChange('apellidoMaterno', component)}
                                            required={true}
                                            error={this.state._errors.apellidoMaterno.error}
                                            disabled={this.state._errors.apellidoPaterno.error || this.state.apellidoPaterno === ''}
                                            helperText=" "
                                        />
                                    </Grid>

                                </Grid>
                            </div>

                        </CardElevation>
                        <br />

                    </Grid>
                    <Grid item xs={12} sm={6}>

                        <CardElevation>
                            <div css={root}>
                                

                                <Grid container spacing={3}>
                                    <Grid item xs={1} >
                                        <Box display="flex" justify="center" alignItems="center" height="100%">
                                            <AddLocationIcon />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <MaterialInput
                                            id="tus-datos-codigo-postal"
                                            type="number"
                                            inputmode="decimal"
                                            autocomplete="postal-code"
                                            label="Código postal"
                                            value={this.state.cp}
                                            pattern="[0-9]{5}"
                                            accept="0-9"
                                            transform={(value) => value.slice(0, 5)}
                                            className=""
                                            error={this.state._errors.cp.error}
                                            onChange={(_event, component) => this.handleCPChange(component)}
                                            required
                                            errorMessage="se aceptan solo 5 dígitos"
                                            disabled={this.state.apellidoPaterno === ''}
                                        />
                                    </Grid>

                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <FormControl variant="outlined" css={cssSelect}>
                                            <InputLabel id="tus-datos-asentamiento-select-label">Colonia</InputLabel>
                                            <Select
                                                onChange={(_event, component) => this.handleChange('asentamiento', { value: _event.target.value + '' })}
                                                labelId="tus-datos-asentamiento-select-label"
                                                id="tus-datos-asentamiento-select"
                                                value={this.state.asentamiento}
                                                required={true}
                                                disabled={this.state.cphab}
                                                label="*Selecciona una colonia"
                                            >
                                                {
                                                    this.renderAsentamientos()
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <MaterialInput
                                            id="tus-datos-municipio"
                                            type="text"
                                            label="Alcaldía / Municipio"
                                            value={this.state.municipio}
                                            pattern="[A-ZÑ]{2,}( [A-ZÑ]{2})?"
                                            accept="A-ZÑ "
                                            transform={(value) => value.toUpperCase().slice(0, 50)}
                                            className=""
                                            onChange={(_event, component) => this.handleChange('municipio', component)}
                                            disabled={true}
                                            helperText=""
                                        />
                                    </Grid>


                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <MaterialInput
                                            type="text"
                                            id="tus-datos-calle"
                                            label="Calle"
                                            value={this.state.calle}
                                            pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                                            accept=" A-ZÑÁÉÍÓÚÜ0-9"
                                            transform={(value) => value.toUpperCase().slice(0, 50)}
                                            className=""
                                            onChange={(_event, component) => this.handleChange('calle', component)}
                                            helperText=""
                                            disabled={this.state._errors.cp.error}
                                        />
                                    </Grid>

                                    <Grid item xs={1}>

                                    </Grid>
                                    <Grid item xs={6}>
                                        <MaterialInput
                                            type="text"
                                            id="tus-datos-no-exterior"
                                            label="No. Exterior"
                                            value={this.state.noExt}
                                            accept="A-Z0-9,.\/\\ "
                                            transform={(value) => value.toUpperCase().slice(0, 10)}
                                            className=" "
                                            onChange={(_event, component) => this.handleChange('noExt', component)}
                                            required={true}
                                            disabled={this.state._errors.calle.error || this.state.calle === '' || this.state._disabled.noExt}
                                            helperText=""
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <MaterialInput
                                            id="tus-datos-no-interior"
                                            type="text"
                                            label="No. Interior"
                                            value={this.state.noInt}
                                            accept="A-Z0-9,.\/\\ "
                                            transform={(value) => value.toUpperCase().slice(0, 10)}
                                            className=" "
                                            onChange={(_event, component) => this.handleChange('noInt', component)}
                                            required={false}
                                            disabled={this.state._errors.noExt.error || this.state.noExt === '' || this.state._disabled.noInt}
                                            helperText=""
                                        />
                                    </Grid>

                                </Grid>
                                <br/>
                                <Grid container justify="center">

                                    <Grid item>
                                        <div css={buttonCss} >
                                            <ButtonDegrade
                                                id="tus-datos-continuar-solicitud-button"
                                                icon={ArrowForwardIcon}
                                                textButton='Continuar con solicitud'
                                                onClick={() => this.submitStepTwo()}
                                                disabled={!this.state.btnSubmit}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </CardElevation>
                    </Grid>
                </Grid>




                <div style={{ padding: "20px 20px 20px 20px" }}>&nbsp;<br /><br />&nbsp;</div>
                <ModalInfo
                    onClose={this.closeModal}
                    autoClose={false}
                    icon={LocationOnIcon}
                    title="Lo sentimos"
                    color="warning"
                    open={this.state.open}
                    body={<div><p>Por el momento no contamos con cobertura en tu ubicación.</p><p>Estamos trabajando para poder atenderte próximamente</p></div>}
                    btnLeft="INTERTAR DE NUEVO"
                    btnRight="TERMINAR"
                    onChange={(data) => this.onChangeModal(data)}
                />
                <Backdrop css={bDrop} open={this.state.bdHabilita} >
                    <CircularProgress color="primary" />
                </Backdrop>
                <Snackbar open={this.state.errorService} autoHideDuration={6000} onClose={() => this.setState({ errorService: false })}>
                    <Alert variant="filled" severity="warning" onClose={() => this.setState({ errorService: false })}>
                        Lo sentimos, por el momento el servicio no se encuentra disponible
                    </Alert>
                </Snackbar>
            </Container>
        );
    }
}

export default CorregirDatos;
